import { createNewPostSubCategories, recipeSubCategories } from '../Models/Category';

// eslint-disable-next-line no-shadow
export enum TabEnum {
  HOME = 0,
  PREGNANTCREATE = 3,
  PARENTCREATE = 4,
  PROFILE = 5,
  RECIPE = 6,
  TIPSPRODUCTS = 7,
  FORUM = 8
}

// eslint-disable-next-line no-shadow
export enum TabValueEnum {
  STARTPAGE = 0,
  RECIPE = 3,
  MATERIAL = 4,
  TIPSPRODUCTS = 5,
  FORUM = 6
}

export const tabs = [
  { tab: 0, route: '/', label: 'Hem', tabIdentifier: TabEnum.HOME },
  { tab: 1, route: '/forum/gravid', label: 'Forum', tabIdentifier: TabEnum.FORUM },
  { tab: 2, route: '/barnmat', label: 'Barnmat', tabIdentifier: TabEnum.RECIPE },
  {
    tab: 3,
    route: '/tips-for-foraldrar',
    label: 'Tips',
    tabIdentifier: TabEnum.TIPSPRODUCTS
  }
] as ITab[];

export const routeTabs = [
  {
    tab: 0,
    route: '/',
    label: 'Hem',
    tabIdentifier: TabEnum.TIPSPRODUCTS
  },
  { tab: 1, route: '/forum/gravid', label: 'Forum', tabIdentifier: TabEnum.FORUM },
  { tab: 1, route: '/forum/gravid/', label: 'Forum', tabIdentifier: TabEnum.FORUM },
  { tab: 1, route: '/forum/gravid/skapa', label: 'Forum', tabIdentifier: TabEnum.FORUM },

  { tab: 1, route: '/forum/foraldrar', label: 'Forum', tabIdentifier: TabEnum.FORUM },
  { tab: 1, route: '/forum/foraldrar/', label: 'Forum', tabIdentifier: TabEnum.FORUM },
  { tab: 1, route: '/forum/foraldrar/skapa', label: 'Forum', tabIdentifier: TabEnum.FORUM },

  { tab: 2, route: '/barnmat', label: 'Barnmat', tabIdentifier: TabEnum.RECIPE },
  { tab: 2, route: '/barnmat/mellanmal', label: 'Barnmat', tabIdentifier: TabEnum.RECIPE },
  { tab: 2, route: '/barnmat/maltid', label: 'Barnmat', tabIdentifier: TabEnum.RECIPE },

  { tab: 3, route: '/tips-for-foraldrar', label: 'Erbjudanden', tabIdentifier: TabEnum.TIPSPRODUCTS },
  {
    tab: 3,
    route: '/tips-for-foraldrar/babyprodukter',
    label: 'Tips',
    tabIdentifier: TabEnum.TIPSPRODUCTS
  },
  {
    tab: 3,
    route: '/tips-for-foraldrar/gravidprodukter',
    label: 'Tips',
    tabIdentifier: TabEnum.TIPSPRODUCTS
  }
] as ITab[];

export const MapRoutes = (tab?: number, route = ''): ITab => {
  const t = routeTabs.find((v) => {
    if (tab !== undefined) {
      return v.tab === tab;
    }
    return route === v.route || v.route.indexOf(route.split('/')[1]) > -1;
  })!;

  return t;
};

export const IsTabRoute = (route: string): boolean => {
  const isTabRoute = routeTabs.find((v) => v.route === route);
  if (isTabRoute) return true;

  const splittedRoute = route.split('/');

  const foundRoutes = routeTabs.filter((x) => x.route.indexOf(`/${splittedRoute[1]}/${splittedRoute[2]}`) > -1);
  if (foundRoutes.length <= 0) return false;

  const foundSubCat = createNewPostSubCategories.filter((x) => x.slug === splittedRoute[3]);
  if (foundSubCat.length > 0) return true;

  const foundRecipeSubCat = recipeSubCategories.filter((x) => x.slug === splittedRoute[2]);
  if (foundRecipeSubCat.length > 0) return true;

  return false;
};

export interface ITab {
  tab: number;
  route: string;
  label: string;
  tabIdentifier: TabEnum;
}
