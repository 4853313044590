/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Adsense } from '@ctrl/react-adsense';
import { IForumPostModel } from '../Models/ForumPostModel';
import { IDictionary, ILike } from '../Models/LikeModel';
import PreviewPost from './PreviewPost';
import { getLikeId, googleAdsenseClientId, googleAdsenseSlotId, isLocalHost } from '../utils/Helper';
import { IPostFeed } from '../Models/FeedModel';
import { RecipeModel } from '../Models/RecipeModel';

type Props = {
  posts: IForumPostModel[] | IPostFeed[] | RecipeModel[];
  likes?: IDictionary<ILike> | undefined;
  disablePostActions?: boolean;
};

const ForumList = (props: Props): JSX.Element => {
  const { posts, likes, disablePostActions } = props;
  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div>
      {(posts as any[]).map((value, index) => {
        const likeId = getLikeId(value.id, likes);
        const _index = index + 1;
        if (_index % 4 === 0 && isMounted) {
          return (
            <div key={`${value.id}div`}>
              <PreviewPost key={value.id} post={value} likeId={likeId} disablePostActions={disablePostActions} />
              <Adsense
                className="listContainer-googleads "
                client={googleAdsenseClientId}
                slot={googleAdsenseSlotId}
                layout="in-article"
                format="fluid"
                data-adtest={isLocalHost() ? 'on' : 'off'}
              />
            </div>
          );
        }
        return <PreviewPost key={value.id} post={value} likeId={likeId} disablePostActions={disablePostActions} />;
      })}
    </div>
  );
};

export default ForumList;
